
import { mapGetters } from "vuex";
import { getSpriteBackgroundYPositions } from "@utils";
import {
	DICTIONARY_LISTS,
	GAME_ICONS_SPRITE_META_SMALL_ICONS,
	GAME_ICONS_SPRITE_META_BIG_ICONS,
} from "@consts";
export default {
	name: "GameSelectTrigger",
	computed: {
		...mapGetters({
			dictionary: "query/currentDictionary",
			isResultsPage: "navigation/isResultsPage",
			isAnyScrabblePage: "navigation/isAnyScrabblePage",
		}),
		gameLabel() {
			let str = "";

			if (this.dictionary?.id === "ALL" && !this.isResultsPage) {
				str = this.$t(
					"SearchSubsectionTextAllDictionariesNoPointsDesktop"
				);
			} else if (this.dictionary?.id === "WWF" && !this.isResultsPage) {
				str = this.$t("SearchSubsectionTextWWFMobile");
			} else {
				str = this.$t(this.dictionary?.text);
			}

			return str.replace(/®/g, "");
		},
		dictSpriteCoord() {
			return getSpriteBackgroundYPositions(
				DICTIONARY_LISTS.DEFAULT,
				this.isSmallDevice
					? GAME_ICONS_SPRITE_META_BIG_ICONS
					: GAME_ICONS_SPRITE_META_SMALL_ICONS
			);
		},
		buttonTitle() {
			return this.$t(this.dictionary?.titleAttribute);
		},
		buttonAriaLabel() {
			return this.$t(this.dictionary?.ariaLabel);
		},
	},
	methods: {
		onButtonClick(e) {
			this.$emit("click", e);
		},
	},
};
