import ClickOutside from "vue-click-outside";
import { ADVANCED_FIELD_TYPES } from "@consts";

export default {
	name: "AdvancedSearchControl",
	directives: {
		ClickOutside,
	},
	props: {
		fieldType: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: "",
		},
	},
	data() {
		return {
			showHelpWindow: false,
			fieldHasFocus: false,
		};
	},
	computed: {
		isWordLengthField() {
			return this.fieldType === ADVANCED_FIELD_TYPES.LENGTH;
		},
		isContainsField() {
			return this.fieldType === ADVANCED_FIELD_TYPES.WITH;
		},
		fieldId() {
			return `asc_input_${this.fieldType}`;
		},
		inputType() {
			return this.isWordLengthField ? "number" : "search";
		},
		fieldTitle() {
			switch (this.fieldType) {
				case ADVANCED_FIELD_TYPES.STARTS:
					return this.$t("AdvancedSearchStartsWithAriaLabel");
				case ADVANCED_FIELD_TYPES.ENDS:
					return this.$t("AdvancedSearchEndsWithAriaLabel");
				case ADVANCED_FIELD_TYPES.WITH:
					return this.$t("AdvancedSearchContainsAriaLabel");
				case ADVANCED_FIELD_TYPES.LENGTH:
					return this.$t("AdvancedSearchLengthTitleAttribute");
				default:
					return this.fieldType;
			}
		},
		fieldLabel() {
			switch (this.fieldType) {
				case ADVANCED_FIELD_TYPES.STARTS:
					return this.$t("AdvancedSearchStartsTextB");
				case ADVANCED_FIELD_TYPES.ENDS:
					return this.$t("AdvancedSearchEndsTextB");
				case ADVANCED_FIELD_TYPES.WITH:
					return this.$t("AdvancedSearchContainsText");
				case ADVANCED_FIELD_TYPES.LENGTH:
					return this.$t("AdvancedSearchLengthText");
				default:
					return this.fieldType;
			}
		},
		fieldAriaLabel() {
			switch (this.fieldType) {
				case ADVANCED_FIELD_TYPES.STARTS:
					return this.$t("AdvancedSearchStartsWithAriaLabel");
				case ADVANCED_FIELD_TYPES.ENDS:
					return this.$t("AdvancedSearchEndsWithAriaLabel");
				case ADVANCED_FIELD_TYPES.WITH:
					return this.$t("AdvancedSearchContainsAriaLabel");
				case ADVANCED_FIELD_TYPES.LENGTH:
					return this.$t("AdvancedSearchLengthTooltipAriaLabel");
				default:
					return null;
			}
		},
		placeholder() {
			switch (this.fieldType) {
				case ADVANCED_FIELD_TYPES.STARTS:
					return "A";
				case ADVANCED_FIELD_TYPES.ENDS:
					return "B";
				case ADVANCED_FIELD_TYPES.WITH:
					return "A_B";
				default:
					return null;
			}
		},
		fieldMinValue() {
			return this.isWordLengthField ? 2 : null;
		},
		fieldMaxValue() {
			return this.isWordLengthField ? 20 : null;
		},
		fieldMaxLength() {
			switch (this.fieldType) {
				case ADVANCED_FIELD_TYPES.STARTS:
				case ADVANCED_FIELD_TYPES.ENDS:
				case ADVANCED_FIELD_TYPES.WITH:
					return 20;
				case ADVANCED_FIELD_TYPES.LENGTH:
					return 2;
				default:
					return 0;
			}
		},
		fieldHasInput() {
			return this.value?.length > 0;
		},
		helpContent() {
			switch (this.fieldType) {
				case ADVANCED_FIELD_TYPES.STARTS:
					return this.$t("AdvancedSearchStartsTooltip");
				case ADVANCED_FIELD_TYPES.ENDS:
					return this.$t("AdvancedSearchEndsTooltip");
				case ADVANCED_FIELD_TYPES.WITH:
					return this.$t("AdvancedSearchContainsTooltip");
				case ADVANCED_FIELD_TYPES.LENGTH:
					return this.$t("AdvancedSearchLengthTooltip");
			}

			return "";
		},
		isResultsPage() {
			return this.$store.getters["navigation/isResultsPage"];
		},
	},
	methods: {
		onFieldInput(event) {
			this.$emit("input", event.target.value);
			event.target.value = this.value;
		},
		onFieldChange(event) {
			const fieldLabel = this.fieldLabel.replace(":", "").toLowerCase();
			const eventID = {
				[ADVANCED_FIELD_TYPES.WITH]: 54,
				[ADVANCED_FIELD_TYPES.ENDS]: 55,
				[ADVANCED_FIELD_TYPES.LENGTH]: 56,
				[ADVANCED_FIELD_TYPES.STARTS]: 57,
			};

			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Advanced Search",
				eventAction: `Change ${fieldLabel} value`,
				eventLabel: event.target.value,
				eventID: eventID[this.fieldType],
			});
		},
		onFieldFocus() {
			this.fieldHasFocus = true;
			if (this.isWordLengthField) {
				this.$store.dispatch("tracking/trackEvent", {
					eventCategory: "Advanced Search",
					eventAction: "Click menu",
					eventLabel: this.$t("AdvancedSearchLengthText"),
					eventID: 74,
				});
			}
		},
		onFieldBlur() {
			this.fieldHasFocus = false;
		},
		onClearButtonClick() {
			const fieldLabel = this.fieldLabel.replace(":", "").toLowerCase();
			const eventID = {
				[ADVANCED_FIELD_TYPES.WITH]: 59,
				[ADVANCED_FIELD_TYPES.ENDS]: 62,
				[ADVANCED_FIELD_TYPES.LENGTH]: 72,
				[ADVANCED_FIELD_TYPES.STARTS]: 83,
			};
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Advanced Search",
				eventAction: `Click ${fieldLabel} clear button`,
				eventID: eventID[this.fieldType],
			});
			this.$emit("input", "");
		},
		onHelpButtonClick() {
			const fieldLabel = this.fieldLabel.replace(":", "").toLowerCase();
			const eventID = {
				[ADVANCED_FIELD_TYPES.WITH]: 60,
				[ADVANCED_FIELD_TYPES.ENDS]: 63,
				[ADVANCED_FIELD_TYPES.LENGTH]: 73,
				[ADVANCED_FIELD_TYPES.STARTS]: 84,
			};
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Advanced Search",
				eventAction: `Click ${fieldLabel} help button`,
				eventID: eventID[this.fieldType],
			});
			this.showHelpWindow = !this.showHelpWindow;
		},
		onHelpWindowClickOutside(e) {
			const isButtonClick =
				e.target === this.$refs.helpButton ||
				e.composedPath().includes(this.$refs.helpButton);
			if (!isButtonClick) {
				this.showHelpWindow = false;
			}
		},
	},
};
