
import { mapGetters } from "vuex";

import {
	getDictionaries,
	getSelectableDictionaries,
	getSpriteBackgroundYPositions,
} from "@utils";
import {
	DICTIONARY_LISTS,
	GAME_ICONS_SPRITE_META_SMALL_ICONS,
	GAME_ICONS_SPRITE_META_BIG_ICONS,
} from "@consts";
export default {
	name: "GameSelect",
	computed: {
		...mapGetters({
			dictionary: "query/currentDictionary",
			isResultsPage: "navigation/isResultsPage",
			isAnyScrabblePage: "navigation/isAnyScrabblePage",
		}),
		dictionaries() {
			if (this.isAnyScrabblePage) {
				return getDictionaries(DICTIONARY_LISTS.SCRABBLE_ONLY);
			}
			return getSelectableDictionaries(DICTIONARY_LISTS.DEFAULT);
		},
		dictSpriteCoord() {
			return getSpriteBackgroundYPositions(
				DICTIONARY_LISTS.DEFAULT,
				this.isSmallDevice
					? GAME_ICONS_SPRITE_META_BIG_ICONS
					: GAME_ICONS_SPRITE_META_SMALL_ICONS
			);
		},
	},
	methods: {
		onGameChange(newDictionary) {
			if (this.dictionary.id !== newDictionary.id) {
				this.$emit("change", newDictionary);
			} else {
				this.$emit("same");
			}
		},
	},
};
