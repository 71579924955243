import ClickOutside from "vue-click-outside";

import {
	removeCharUntilMax,
	isEmpty,
	getDictionaries,
	isWordleDictionary,
	isVueRoute,
	isHybridEnvironment,
	isHybridLocalEnvironment,
	isTilesInBlacklist,
	alphabetizeTiles,
	sortQueryParams,
} from "@utils";
import {
	PAGES,
	UNSCRAMBLER_MIN_LENGTH,
	UNSCRAMBLER_MAX_LENGTH,
	UNSCRAMBLER_MAX_WILDCARDS,
	ADVANCED_FIELD_TYPES,
	DICTIONARIES_IN_API,
	SEARCH_GAMES,
	DICTIONARY_LISTS,
} from "@consts";
import { mapGetters } from "vuex";

export default {
	name: "Finder",
	/**
	 * @todo split into separate files after development is done.
	 */
	props: {
		title: {
			type: String,
			required: false,
			default: "WordFinder & Scrabble cheat",
		},
		subtitle: {
			type: String,
			required: false,
			default: null,
		},
	},
	created() {
		this.loadAdvancedFieldValues();
		if (this.isResultsPage) {
			this.tiles = (this.searchParams?.tiles || "").replace(/_/g, "?");
		}
	},
	watch: {
		"$route.query"() {
			// When changing tiles, component is re-created, but when qs changes
			// the component is not re-created, so we watch it and update em.
			// Also, when component is re-created, this does not trigger
			this.loadAdvancedFieldValues();
		},
	},
	inject: {
		searchParams: {
			default: () => ({}),
		},
	},
	data() {
		return {
			tiles: "",
			advancedFields: {
				[ADVANCED_FIELD_TYPES.STARTS]: "",
				[ADVANCED_FIELD_TYPES.WITH]: "",
				[ADVANCED_FIELD_TYPES.ENDS]: "",
				[ADVANCED_FIELD_TYPES.LENGTH]: null,
			},
			errorMessage: "",
			fieldHasFocus: false,
			dictionaryDropdownIsOpen: false,
			showMobileBoxControls: false, // TODO to my future self: check out if we need this, but I think is effectively doing absolutely nothing
			fieldMaxLength: UNSCRAMBLER_MAX_LENGTH,
			gameSelectIsOpen: false,
			wordle: {
				correct: ["", "", "", "", ""],
				includes: "",
				excludes: "",
			},
		};
	},
	computed: {
		...mapGetters("navigation", [
			"isWordleLandingPage",
			"isQuordleLandingPage",
			"isWordleResultsPage",
			"isQuordleResultsPage",
			"isWordleGamePage",
			"isQuordleGamePage",
			"isAnagramLandingPage",
			"isAnagramResultsPage",
			"isScrabbleDictionaryResultsPage",
			"pageHasDedicatedDictionary",
		]),
		titleComponent() {
			if (this.isResultsPage) {
				return "p";
			}

			return "h1";
		},
		filters() {
			const filterKeys = [
				ADVANCED_FIELD_TYPES.STARTS,
				ADVANCED_FIELD_TYPES.WITH,
				ADVANCED_FIELD_TYPES.ENDS,
				ADVANCED_FIELD_TYPES.LENGTH,
			];

			return filterKeys.map((key) => {
				let value = this.advancedFields[key];
				if (key === ADVANCED_FIELD_TYPES.LENGTH && value === null) {
					value = "";
				}

				return {
					fieldType: key,
					value: String(value),
				};
			});
		},
		isResultsPage() {
			return this.$store.getters["navigation/isResultsPage"];
		},
		isScrabbleMobileResultsStyle() {
			return (
				(this.isScrabbleDictionaryResultsPage ||
					this.isAnagramResultsPage) &&
				this.isSmallDevice
			);
		},
		isPersistentSearch() {
			return this.$store.getters["navigation/isPersistentSearch"];
		},
		showMessage() {
			if (
				this.isScrabbleDictionaryResultsPage ||
				this.isAnagramResultsPage ||
				this.isQuordleGamePage ||
				this.isWordleGamePage
			) {
				return false;
			}

			return true;
		},
		layout() {
			switch (this.$route.name) {
				case PAGES.FOURPICS:
					return "4pics1word";
				case PAGES.SCRABBLE_DICT:
				case PAGES.SCRABBLE_CHECKER:
					return "scrabble-dictionary";
				case PAGES.SCRABBLE_DICT_RESULTS:
					return "scrabble-dictionary-results";
				case PAGES.UNSCRAMBLER_RESULTS:
				case PAGES.DYNAMIC_RESULTS_PAGE:
					return "unscramble-results";
				case PAGES.WORDS_BY_LENGTH_RESULTS:
				case PAGES.WORDS_CONSONANTS:
				case PAGES.WORDS_ENDING_ING:
				case PAGES.WORDS_ENDING_RESULTS:
				case PAGES.WORDS_HAVING_RESULTS:
				case PAGES.WORDS_STARTING_RESULTS:
				case PAGES.WORDS_VOWELS:
				case PAGES.WORDS_WITH_Q_NO_U:
				case PAGES.WORDS_COMBINATION_LETTERS_RESULTS:
				case PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS:
					return "word-list-result";
				default:
					return "default";
			}
		},
		isUnscrambleResultsLayout() {
			return this.layout === "unscramble-results";
		},
		isScrabbleLayout() {
			return (
				this.layout === "scrabble-dictionary" ||
				this.layout === "scrabble-dictionary-results"
			);
		},
		is4Pics1WordLayout() {
			return this.layout === "4pics1word";
		},
		isJumbleSolverPage() {
			return this.$route.name === PAGES.JUMBLE_SOLVER;
		},
		isAnagramSolverPage() {
			return this.$route.name === PAGES.ANAGRAM;
		},
		isWordScramblePage() {
			return this.$route.name === PAGES.WORD_SCRAMBLE;
		},
		isAnagramResultsPage() {
			return this.$route.name === PAGES.ANAGRAM_RESULTS;
		},
		isWordListLayout() {
			return this.layout === "word-list-result";
		},
		isDefaultLayout() {
			return this.layout === "default";
		},
		dictionaryDropdownIsEnabled() {
			// These pages will never have a dictionary option.
			if (this.pageHasDedicatedDictionary) {
				return false;
			}

			// Results pages will never have the dictionary dropdown
			if (this.isResultsPage) {
				return false;
			}

			return this.isDefaultLayout || this.isScrabbleLayout;
		},
		advancedSearchIsEnabled() {
			// These pages will never have the advanced search
			if (
				this.isAnagramResultsPage ||
				this.isAnagramSolverPage ||
				this.isScrabbleLayout ||
				this.isJumbleSolverPage
			) {
				return false;
			}

			return true;
		},
		showAdvancedSearchSubmitButton() {
			if (
				this.isScrabbleDictionaryResultsPage ||
				this.isAnagramResultsPage
			) {
				return true;
			}

			if (this.isWordleLandingPage || this.isQuordleLandingPage) {
				return true;
			}

			return this.advancedSearchIsEnabled;
		},
		message() {
			if (this.errorMessage) {
				return this.errorMessage;
			}

			return this.subtitle || this.$t("SearchBoxWildcardText");
		},
		fieldPlaceholder() {
			return this.fieldHasFocus ? "" : this.$t("SearchBoxGameEmptyText");
		},
		totalWildcardCount() {
			/**
			 * @todo this should be updated. Starts and Ends no longer support wildcards.
			 */
			const wildcardCount = [
				this.tiles,
				this.advancedFields[ADVANCED_FIELD_TYPES.STARTS],
				this.advancedFields[ADVANCED_FIELD_TYPES.ENDS],
			].map((value) => {
				return (value.match(/\?/g) || []).length;
			});

			wildcardCount.push(
				(
					this.advancedFields[ADVANCED_FIELD_TYPES.WITH].match(
						/_/g
					) || []
				).length
			);

			return wildcardCount.reduce((total, current) => total + current, 0);
		},
		hasInput() {
			return this.tiles?.length > 0;
		},
		advancedHasInput() {
			return this.filters.some((filter) => filter.value);
		},
		wordLengthIsValid() {
			if (isEmpty(this.advancedFields[ADVANCED_FIELD_TYPES.LENGTH])) {
				return true;
			}

			const length = parseInt(
				this.advancedFields[ADVANCED_FIELD_TYPES.LENGTH]
			);
			return (
				length >= UNSCRAMBLER_MIN_LENGTH &&
				length <= UNSCRAMBLER_MAX_LENGTH
			);
		},
		showMainLeftSubmitButton() {
			if (
				this.isResultsPage &&
				!this.isScrabbleDictionaryResultsPage &&
				!this.isAnagramResultsPage
			) {
				return false;
			}

			return this.hasInput || this.fieldHasFocus;
		},
		showDictionaryDropdownButton() {
			if (this.isResultsPage) {
				return false;
			}
			return (
				this.dictionaryDropdownIsEnabled &&
				!this.showMainLeftSubmitButton
			);
		},
		showDictionaryDropdown() {
			return (
				this.dictionaryDropdownIsEnabled &&
				this.dictionaryDropdownIsOpen
			);
		},
		showClearButton() {
			if (this.isResultsPage) {
				return this.hasInput;
			}

			return this.hasInput || this.fieldHasFocus;
		},
		showMainRightSubmitButton() {
			if (this.showClearButton || this.advancedHasInput) {
				return false;
			}

			if (this.isResultsPage) {
				return false;
			}

			return true;
		},
		mainRightSubmitButtonIsEnabled() {
			if (this.isWordleGamePage || this.isQuordleGamePage) {
				return this.hasWordleInput;
			}

			if (!this.hasInput && !this.advancedHasInput) {
				return false;
			}

			if (!this.wordLengthIsValid) {
				return false;
			}

			return true;
		},
		dictionary() {
			return this.$store.getters["query/currentDictionary"];
		},
		fieldFontsize() {
			const letters = 15;
			const fontSize = 22;
			// make the letters progressively smaller when the query gets over 15 letters.
			if (this.isSmallDevice && this.tiles.length >= letters) {
				return `${fontSize - (this.tiles.length - letters)}px`;
			}
			return null;
		},
		fieldLeftMarginMobile() {
			if (
				this.showMainLeftSubmitButton ||
				this.dictionaryDropdownIsEnabled
			) {
				return 0;
			}

			if (this.hasInput || this.fieldHasFocus) {
				return 55;
			}

			return 0;
		},
		fieldLeftMarginDesktop() {
			/**
			 * @todo find a less complicated way to handle this mess.
			 *
			 * The left submit button needs to show in Scrabble mobile, but not in scrabble desktop.
			 * To avoid a hydration issue, `showMainLeftSubmitButton` returns true for Scrabble for both mobile and desktop.
			 *
			 * But that means over here, we have to make sure that a left margin is still implemented on desktop, because
			 * even though `showMainLeftSubmitButton` returns true, nothing is actually *shown*.
			 *
			 * The solution would be to use `showMainLeftSubmitButton` (and all other such computed properties) as v-show instead
			 * of v-if conditions. And optionally, combine that with tailwind classes to avoid CSS priority issues.
			 */
			if (
				(this.showMainLeftSubmitButton &&
					!this.isScrabbleDictionaryResultsPage &&
					!this.isAnagramResultsPage) ||
				this.dictionaryDropdownIsEnabled
			) {
				return 0;
			}

			if (this.hasInput || this.fieldHasFocus) {
				return this.isResultsPage ? 40 : 75;
			}

			return 0;
		},
		fieldRightMarginMobile() {
			if (
				this.isScrabbleMobileResultsStyle ||
				this.isAnagramResultsPage
			) {
				return 43;
			}

			if (
				this.dictionaryDropdownIsEnabled ||
				this.hasInput ||
				this.fieldHasFocus
			) {
				return 55;
			}

			return 0;
		},
		fieldRightMarginDesktop() {
			if (this.hasInput || this.fieldHasFocus) {
				return this.isResultsPage ? 40 : 75;
			}

			if (this.dictionaryDropdownIsEnabled) {
				return 160;
			}

			return 0;
		},
		tilesWordleCorrect: {
			get() {
				return this.wordle.correct;
			},
			set(val) {
				if (isEmpty(this.wordle.excludes)) {
					this.wordle.correct = [...val];
					return;
				}

				// If correct tiles value is present, remove any matching characters from exclude string.
				if (val) {
					const reg = new RegExp(
						(val || []).filter((v) => !isEmpty(v)).join("|"),
						"g"
					);
					this.wordle.excludes = this.wordle.excludes.replace(
						reg,
						""
					);
				}

				this.wordle.correct = [...val];
			},
		},
		tilesWordleIncludes: {
			get() {
				return this.wordle.includes;
			},
			set(val) {
				if (isEmpty(this.wordle.excludes)) {
					this.wordle.includes = val;
					return;
				}

				// If include value is present, remove any matching characters from exclude string.
				if (val) {
					const reg = new RegExp(
						(val || "").split("").join("|"),
						"g"
					);
					this.wordle.excludes = this.wordle.excludes.replace(
						reg,
						""
					);
				}

				this.wordle.includes = val;
			},
		},
		tilesWordleExcludes: {
			get() {
				return this.wordle.excludes;
			},
			set(val) {
				// Prevent duplicate letters in the excludes field.
				val = Array.from(new Set(val)).join("");

				if (
					isEmpty(this.wordle.includes) &&
					isEmpty(this.wordle.correct.join(""))
				) {
					this.wordle.excludes = val;
					return;
				}

				// If any of the `includes` or `correct` fields are present, do not allow any characters that are already in any of those fields.
				if (!isEmpty(this.wordle.includes)) {
					const regIncludes = new RegExp(
						(this.wordle.includes || "").split("").join("|"),
						"g"
					);
					val = val.replace(regIncludes, "");
				}

				if (!isEmpty(this.wordle.correct.join(""))) {
					const regCorrect = new RegExp(
						(this.wordle.correct || [])
							.filter((v) => !isEmpty(v))
							.join("|"),
						"g"
					);
					val = val.replace(regCorrect, "");
				}

				this.wordle.excludes = val;
			},
		},
		wordleResultsUrl() {
			const w = this.wordle;
			let correct = w.correct.map((letter) => letter || "_");
			correct = correct.some((letter) => letter !== "_")
				? correct.join("")
				: null;

			if (!correct && !w.includes && !w.excludes) {
				return null;
			}

			let d = {
				correct,
				includes: w.includes || null,
				excludes: w.excludes || null,
			};

			d = Object.entries(d)
				.filter((entry) => entry[1])
				.map(([key, value]) => `${key}=${value}`)
				.join("&");

			return `/wordle/results/?${d}`;
		},
		quordleResultsUrl() {
			return this.wordleResultsUrl?.replace("wordle", "quordle");
		},
		hasWordleInput() {
			return this.wordleResultsUrl !== null;
		},
	},
	methods: {
		loadAdvancedFieldValues() {
			const paramMap = {
				[ADVANCED_FIELD_TYPES.STARTS]: "starts",
				[ADVANCED_FIELD_TYPES.WITH]: "contains",
				[ADVANCED_FIELD_TYPES.ENDS]: "ends",
				[ADVANCED_FIELD_TYPES.LENGTH]: "length",
			};

			this.filters.forEach((filter) => {
				const fieldType = filter.fieldType;
				const defaultValue =
					fieldType === ADVANCED_FIELD_TYPES.LENGTH ? null : "";

				let value =
					this.searchParams?.advanced?.[paramMap[fieldType]] ||
					defaultValue;

				if (fieldType === ADVANCED_FIELD_TYPES.WITH) {
					value = value.replace(/^(\w+)-and-(\w+)$/, "$1$2");
				}

				this.advancedFields[fieldType] = value;
			});
		},
		clearFormErrors() {
			this.errorMessage = "";
		},
		onAdvancedSearchControlInput(type, value) {
			this.onFieldInput(type, value);
			this.clearFormErrors();
		},
		onGameSelectTriggerClick() {
			this.gameSelectIsOpen = !this.gameSelectIsOpen;
		},
		onGameSelectClickOutside(e) {
			if (this.isSmallDevice) {
				return;
			}

			const trigger = this.$refs?.gameSelectTrigger?.$el;
			if (trigger && e.composedPath().includes(trigger)) {
				return false;
			}

			this.gameSelectIsOpen = false;
		},
		onGameChange(newDictionary) {
			this.$store.commit("query/setDictionary", newDictionary.value);
			// this.$store.commit("updatePrefixedTiles", ""); // TODO find out if this is necessary

			const eventID = {
				[DICTIONARIES_IN_API.WWF]: 75,
				[DICTIONARIES_IN_API.US]: 76,
				[DICTIONARIES_IN_API.UK]: 77,
				[`${DICTIONARIES_IN_API.US}${DICTIONARIES_IN_API.UK}`]: 78,
				[DICTIONARIES_IN_API.CHU]: 80,
				[DICTIONARIES_IN_API.ALL]: 81,
			};

			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Game selection",
				eventAction: "Selects dictionary from scroll-down menu",
				eventLabel: this.$t(this.dictionary.text),
				eventID: eventID[this.dictionary.value],
			});

			/**
			 * @note
			 * this second GA event is what was originally in the
			 * AdvancedFilters component. While it's ridiculous to have
			 * both GA events next to each other, we're keeping it here
			 * for consistency while we decide what to do with the
			 * GA events. We're going to be migrating GA soon anyway.
			 */
			const eventCategory = this.isScrabbleResultsPage
				? "Scrabble Dictionary"
				: "Filter menus";
			const eventAction = this.isScrabbleResultsPage
				? `Select ${this.$t(this.dictionary.text)} dictionary`
				: `Click on ${this.$t(this.dictionary.text)} dictionary`;
			const eventLabel = this.isScrabbleResultsPage
				? `ScrabbleDictionaryResultsTextScrabble${this.dictionary.value}`
				: this.dictionary.text;
			const secondEventID = {
				[DICTIONARIES_IN_API.WWF]: 48,
				[DICTIONARIES_IN_API.US]: this.isScrabbleResultsPage ? 126 : 49,
				[DICTIONARIES_IN_API.UK]: this.isScrabbleResultsPage ? 127 : 50,
				[`${DICTIONARIES_IN_API.US}${DICTIONARIES_IN_API.UK}`]: 51,
				[DICTIONARIES_IN_API.CHU]: 52,
			};

			this.$store.dispatch("tracking/trackEvent", {
				eventID: secondEventID[this.$t(this.dictionary.value)],
				eventCategory,
				eventAction,
				eventLabel,
			});

			if (this.isPersistentSearch) {
				this.$emit("change", newDictionary);
			}

			this.gameSelectIsOpen = false;
			this.closeMobileBoxControls();
		},
		onGameNotChange() {
			this.gameSelectIsOpen = false;
			this.closeMobileBoxControls();
		},
		onMainFieldInput(event) {
			const cleanValue = this.onFieldInput("tiles", event.target.value);
			event.target.value = cleanValue;
		},
		onFieldInput(fieldType, value) {
			const isMainField = fieldType === "tiles";

			if (fieldType === ADVANCED_FIELD_TYPES.LENGTH) {
				let newValue = value.replace(/\D+/g, "");
				newValue = isEmpty(newValue) || newValue <= 0 ? null : newValue;
				this.advancedFields[fieldType] = parseInt(newValue) || null;
				return newValue;
			}

			if (isMainField) {
				const gameAcceptsWildcards = !(
					this.isScrabbleLayout ||
					this.isJumbleSolverPage ||
					this.isAnagramResultsPage ||
					this.isAnagramSolverPage ||
					this.isWordScramblePage
				);

				if (!gameAcceptsWildcards) {
					const newValue = value.replace(/[^a-zA-Z]/g, "");
					this[fieldType] = newValue;
					return newValue;
				}
			}

			const isContains = fieldType === ADVANCED_FIELD_TYPES.WITH;
			let fieldValidationRegex = isContains
				? /[^a-zA-Z_]/g
				: /[^a-zA-Z?]/g;

			if (
				[
					ADVANCED_FIELD_TYPES.STARTS,
					ADVANCED_FIELD_TYPES.ENDS,
				].includes(fieldType)
			) {
				fieldValidationRegex = /[^a-zA-Z]/g;
			}

			const wildcardCharacter = isContains ? "_" : "?";
			const wildcardRegex = isContains ? /_/g : /\?/g;

			const oldValue = isMainField
				? this[fieldType]
				: this.advancedFields[fieldType];

			let newValue = (value || "")
				.replace(/[\s?]/g, wildcardCharacter)
				.replace(fieldValidationRegex, "")
				.toUpperCase();

			if (isMainField || isContains) {
				const oldWildcardCount = (oldValue.match(wildcardRegex) || [])
					.length;
				const newWildcardCount = (newValue.match(wildcardRegex) || [])
					.length;

				const oldTotalWildcardCount = this.totalWildcardCount;
				const newTotalWildcardCount =
					oldTotalWildcardCount - oldWildcardCount + newWildcardCount;

				const wildcardsToRemove =
					newTotalWildcardCount - UNSCRAMBLER_MAX_WILDCARDS;

				if (wildcardsToRemove > 0) {
					newValue = removeCharUntilMax(
						newValue,
						wildcardCharacter,
						newWildcardCount - wildcardsToRemove
					);
				}
			}

			if (isMainField) {
				this[fieldType] = newValue;
			} else {
				this.advancedFields[fieldType] = newValue;
			}
			return newValue;
		},
		onFieldFocus() {
			this.fieldHasFocus = true;
		},
		onFieldBlur() {
			this.fieldHasFocus = false;
		},
		/**
		 * @todo remove this, we're not using it any longer.
		 */
		deviceSpecificDictionaryOptions() {
			if (this.isScrabbleLayout) {
				return getDictionaries(DICTIONARY_LISTS.SCRABBLE_ONLY);
			}

			const deviceSpecificDictionaryOptions = getDictionaries(
				DICTIONARY_LISTS.DEFAULT
			);

			if (this.isSmallDevice) {
				deviceSpecificDictionaryOptions.find(
					(dictionary) => dictionary.id === "ALL"
				).text = "SearchSubsectionTextAllDictionariesNoPoints";
			}
			return deviceSpecificDictionaryOptions.filter(
				(d) => !isWordleDictionary(d) // we dont want to show wordle at all
			);
		},
		openMobileBoxControls() {
			this.showMobileBoxControls = true;
			if (process.client) {
				document.body.classList.add("disable-scroll");
			}
		},
		closeMobileBoxControls() {
			this.showMobileBoxControls = false;
			if (process.client) {
				document.body.classList.remove("disable-scroll");
			}
		},
		updatePreferences() {},
		onClearButtonClick() {
			this.tiles = "";
			this.$refs.searchInput.focus();
		},
		getWordListResultsPagePath() {
			const startsWithNoWildcards = removeCharUntilMax(
				this.advancedFields[ADVANCED_FIELD_TYPES.STARTS],
				"?",
				0
			);
			const endsWithNoWildcards = removeCharUntilMax(
				this.advancedFields[ADVANCED_FIELD_TYPES.ENDS],
				"?",
				0
			);
			const containsNoWildcards = removeCharUntilMax(
				this.advancedFields[ADVANCED_FIELD_TYPES.WITH],
				"_",
				0
			);

			const contains = this.advancedFields[ADVANCED_FIELD_TYPES.WITH];

			const hasStartsWith = !isEmpty(startsWithNoWildcards);
			const hasContains = !isEmpty(containsNoWildcards);
			const hasEndsWith = !isEmpty(endsWithNoWildcards);
			const hasLength = !isEmpty(
				this.advancedFields[ADVANCED_FIELD_TYPES.LENGTH]
			);

			if (hasLength || hasContains) {
				const base = hasLength
					? "letter-words"
					: "words-with-the-letter";

				const segments = [];

				if (hasLength) {
					segments.push(
						this.advancedFields[ADVANCED_FIELD_TYPES.LENGTH]
					);
				}

				if (hasStartsWith) {
					segments.push(`starts-${startsWithNoWildcards}`);
				}

				if (hasContains) {
					const onlyHasContains =
						hasContains &&
						!hasStartsWith &&
						!hasEndsWith &&
						!hasLength;

					segments.push(
						onlyHasContains ? contains : `with-${contains}`
					);
				}

				if (hasEndsWith) {
					segments.push(`ends-${endsWithNoWildcards}`);
				}

				return `/${base}/${segments.join("-")}/`;
			}

			if (hasStartsWith && hasEndsWith) {
				return `/words-with-the-letter/starts-${startsWithNoWildcards}-ends-${endsWithNoWildcards}/`;
			}

			if (hasStartsWith) {
				return `/words-that-start/${startsWithNoWildcards}/`;
			}

			if (hasEndsWith) {
				const nextPagePath =
					`/ending-with/${endsWithNoWildcards}/`.toLowerCase();

				return nextPagePath === "/ending-with/ing/"
					? "/ending-in-ing/"
					: nextPagePath;
			}

			// The following checks if each field only consists of wildcards.
			const startsWithOnlyHasWildcards =
				!startsWithNoWildcards.length &&
				this.advancedFields[ADVANCED_FIELD_TYPES.STARTS].length;
			const containsOnlyHasWildcards =
				!containsNoWildcards.length &&
				this.advancedFields[ADVANCED_FIELD_TYPES.WITH].length;
			const endsWithOnlyHasWildcards =
				!endsWithNoWildcards.length &&
				this.advancedFields[ADVANCED_FIELD_TYPES.ENDS].length;

			if (
				startsWithOnlyHasWildcards ||
				containsOnlyHasWildcards ||
				endsWithOnlyHasWildcards
			) {
				const wildcardsCount =
					this.advancedFields[ADVANCED_FIELD_TYPES.STARTS].length +
					this.advancedFields[ADVANCED_FIELD_TYPES.WITH].length +
					this.advancedFields[ADVANCED_FIELD_TYPES.ENDS].length;

				return wildcardsCount <= 2
					? "/letter-words/2/"
					: "/letter-words/3/";
			}

			return null;
		},
		/**
		 * Reads the advanced search filters in the component, filters out the empty ones, and formats them as URL query parameters.
		 * @returns {string[]} an array of the parameters in the format `key=value`
		 */
		getWordListURLParams() {
			/**
			 * @todo @refactor this can be combined with the same logic that is in the static search URL computed property.
			 */
			const urlKeys = {
				[ADVANCED_FIELD_TYPES.STARTS]: "starts",
				[ADVANCED_FIELD_TYPES.WITH]: "contains",
				[ADVANCED_FIELD_TYPES.ENDS]: "ends",
				[ADVANCED_FIELD_TYPES.LENGTH]: "length",
			};

			return this.filters
				.filter(({ fieldType, value }) => !isEmpty(value))
				.map(
					({ fieldType, value }) => `${urlKeys[fieldType]}=${value}`
				);
		},
		getNextPagePath() {
			let safeTiles = isTilesInBlacklist(this.tiles)
				? alphabetizeTiles(this.tiles)
				: this.tiles;
			safeTiles = safeTiles.replace(/\?/g, "_");

			if (this.isWordleGamePage) {
				return this.wordleResultsUrl;
			}

			if (this.isQuordleGamePage) {
				return this.quordleResultsUrl;
			}

			if (!this.hasInput && this.advancedHasInput) {
				const wordListPath = this.getWordListResultsPagePath();
				if (!isEmpty(wordListPath)) {
					return wordListPath;
				}
			}

			if (this.isScrabbleLayout) {
				return `/scrabble-dictionary/${safeTiles}/`;
			}

			if (this.isAnagramLandingPage || this.isAnagramResultsPage) {
				return `/anagram-solver/${safeTiles}/`;
			}

			const startsWithNoWildcards = removeCharUntilMax(
				this.advancedFields[ADVANCED_FIELD_TYPES.STARTS],
				"?",
				0
			);

			const startsWithWildcardCount = (
				this.advancedFields[ADVANCED_FIELD_TYPES.STARTS].match(
					/[?]/g
				) || []
			).length;
			if (startsWithWildcardCount) {
				this.advancedFields[ADVANCED_FIELD_TYPES.STARTS] =
					startsWithNoWildcards;
			}

			const endsWithNoWildcards = removeCharUntilMax(
				this.advancedFields[ADVANCED_FIELD_TYPES.ENDS],
				"?",
				0
			);
			const endsWithWildcardCount = (
				this.advancedFields[ADVANCED_FIELD_TYPES.ENDS].match(/[?]/g) ||
				[]
			).length;
			if (endsWithWildcardCount) {
				this.advancedFields[ADVANCED_FIELD_TYPES.ENDS] =
					endsWithNoWildcards;
			}

			this.tiles = `${this.tiles}${"_".repeat(
				startsWithWildcardCount + endsWithWildcardCount
			)}`;

			const advancedParams = this.getWordListURLParams();
			const advancedQuery = advancedParams.length
				? `?${advancedParams.join("&")}`
				: "";
			return `/unscramble/${safeTiles}/${advancedQuery}`;
		},
		getStaticSearchRedirectionUrl() {
			const params = {};

			/**
			 * @todo implement Wordle and Quordle support here
			 * when the pages are migrated.
			 */
			switch (this.$route.name) {
				case PAGES.SCRABBLE_DICT:
				case PAGES.SCRABBLE_CHECKER:
				case PAGES.SCRABBLE_DICT_RESULTS:
					params.game = SEARCH_GAMES.SCRABBLE;
					break;
				case PAGES.ANAGRAM:
				case PAGES.ANAGRAM_RESULTS:
					params.game = SEARCH_GAMES.ANAGRAM;
					break;
			}

			if (this.tiles) {
				params.tiles = this.tiles;
			}

			const advancedMap = {
				[ADVANCED_FIELD_TYPES.STARTS]: "starts",
				[ADVANCED_FIELD_TYPES.WITH]: "contains",
				[ADVANCED_FIELD_TYPES.ENDS]: "ends",
				[ADVANCED_FIELD_TYPES.LENGTH]: "length",
			};

			this.filters
				.filter((f) => f.value)
				.forEach((f) => {
					params[advancedMap[f.fieldType]] = f.value;
				});

			const dictionary = this.dictionary?.value;
			if (dictionary) {
				params.dictionary = dictionary;
			}

			const paramString = Object.entries(params)
				.map(([key, value]) => `${key}=${value}`)
				.join("&");

			return `/search/?${paramString}`;
		},
		onFormSubmit(event) {
			if (
				!this.hasInput &&
				!this.advancedHasInput &&
				!this.hasWordleInput
			) {
				return;
			}

			this.clearFormErrors();

			if (!isEmpty(event)) {
				this.$store.dispatch("tracking/trackEvent", {
					eventCategory: "Advanced Search",
					eventAction: "Click on magnifying glass search button",
					eventID: 82,
				});
			}

			let newPath = this.getNextPagePath().toLowerCase();
			if (this.dictionary?.value) {
				const connector = newPath.includes("?") ? "&" : "?";
				newPath = `${newPath}${connector}dictionary=${this.dictionary.value}`;
			}

			newPath = sortQueryParams(newPath);

			if (
				newPath.includes(this.$route.path) &&
				sortQueryParams(window.location.search) ===
					`?${newPath.split("?")[1]}`
			) {
				this.$emit("same");
				return;
			}

			this.$emit("close-search-and-load");

			if (newPath === sortQueryParams(this.$route.fullPath)) {
				/**
				 * @todo one more todo because why not:
				 * Now that we have the advanced fields in the query params,
				 * I think this situation will never be reached. The "same"
				 * event will always be fired instead.
				 */
				/**
				 * @todo update this @todo, we don't use GET_WORDS anymore
				 */
				/**
				 * @todo right now the 4pics search uses the
				 * new search action (GET_WORDS) on the home page
				 * but still uses the old one (executeQuery) if the
				 * search is made from the result page. Take this chance
				 * to update it and get rid of the old method.
				 */
				this.$emit("submit-search");
			} else if (
				!isVueRoute(newPath, true) ||
				(!isHybridEnvironment(window?.location.origin) &&
					!isHybridLocalEnvironment(window?.location.origin))
			) {
				this.$router.push(newPath);
			} else if (isHybridEnvironment(window?.location.origin)) {
				window?.location.assign(this.getStaticSearchRedirectionUrl());
			} else if (isHybridLocalEnvironment(window?.location.origin)) {
				window?.location.assign(
					`http://wordfinder-local.yourdictionary.com:8080${this.getStaticSearchRedirectionUrl()}`
				);
			}
		},
	},
	directives: {
		ClickOutside,
	},
	mounted() {
		if (this.isWordleResultsPage || this.isQuordleResultsPage) {
			this.wordle.correct = (this.$route.query.correct || "_____")
				.split("")
				.map((v) => v.replace("_", "")) || ["", "", "", "", ""];
			this.wordle.includes = this.$route.query.includes || "";
			this.wordle.excludes = this.$route.query.excludes || "";
		}

		if (
			(!this.isSmallOrMediumDevice && this.isResultsPage) ||
			this.isScrabbleMobileResultsStyle
		) {
			if (this.$refs.searchInput) {
				// Focus and force input caret to be at the end of the field
				this.$refs.searchInput.focus();
				const valLength = this.$refs.searchInput.value.length;
				this.$refs.searchInput.setSelectionRange(valLength, valLength);
			}
		}
	},
};
