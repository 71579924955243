
import ClickOutside from "vue-click-outside";
import { allowOnlyEnChar } from "@utils";

export default {
	directives: {
		ClickOutside,
	},
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		id: {
			required: true,
			type: String,
		},
		value: {
			required: true,
			type: String,
		},
		placeholder: {
			required: true,
			type: String,
		},
		maxSearchLetters: {
			required: true,
			type: Number,
		},
		isForLanding: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isInputFocus: false,
			localValue: this.value,
		};
	},
	computed: {
		hasInput() {
			return this.value?.length > 0;
		},
		searchInputFontsize() {
			const letters = this.isSmallDevice ? 13 : 14;
			const fontSize = this.localValue.length >= letters ? 21 : 25;
			// make the letters progressively smaller when the query gets over 15 letters.
			if (this.localValue.length >= letters) {
				return `${fontSize - (this.localValue.length - letters)}px`;
			}
			return null;
		},
	},
	watch: {
		value(newValue) {
			this.localValue = newValue;
			if (this.$refs.searchInput) {
				this.$refs.searchInput.value = this.localValue;
			}
		},
	},
	methods: {
		onSearchInputInput(el) {
			const value = allowOnlyEnChar(el.target.value).replace(
				/\s|\?|_/g,
				""
			);
			el.target.value = this.localValue = value;

			this.$emit("input", this.localValue);
			this.localValue = this.value;
		},
		onSearchInputFocus() {
			this.isInputFocus = !this.isInputFocus;
		},
		onClearButtonClick() {
			this.$refs.searchInput.focus();
			this.localValue = "";
			this.$emit("input", this.localValue);
		},
	},
};
