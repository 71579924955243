export default {
	name: "MobileBoxControls",
	props: {
		title: {
			type: String,
			required: true,
		},
		fullHeight: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onCancelButtonClick() {
			this.$emit("close");
		},
	},
};
