
export default {
	props: {
		text: {
			required: true,
			type: String,
		},
		smallText: {
			required: false,
			type: String,
			default: null,
		},
		showSmallText: {
			required: false,
			type: Boolean,
			default: true,
		},
	},
};
