
const KEY_CODE = {
	BACKSPACE: 8,
	TAB: 9,
	ENTER: 13,
	SHIFT: 16,
	SPACEBAR: 32,
	LEFT: 37,
	RIGHT: 39,
};

const LETTER_RANGE_A_Z = {
	START: 65,
	END: 90,
};

export default {
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			required: true,
			type: Array,
		},
		forceSmall: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			correctTiles: ["", "", "", "", ""],
		};
	},
	watch: {
		value(newVal) {
			this.correctTiles = newVal;
		},
	},
	mounted() {
		this.correctTiles = this.value;
	},
	methods: {
		onInput(e, index) {
			this.correctTiles[index] = e.target.value;
			this.$emit("input", this.correctTiles);
		},
		onKeyUp(e, index) {
			if (this.isLastTile(index)) {
				return;
			}

			if (
				(this.isTileFilledIn(index) && this.isLetter(e.keyCode)) ||
				(!this.isTileFilledIn(index) && this.isSpacebar(e.keyCode))
			) {
				this.focusToInput(index + 1);
			}
		},
		onKeyDown(e, index) {
			if (e.keyCode === KEY_CODE.BACKSPACE) {
				if (this.isFirstTile(index)) {
					return;
				}

				if (!this.isTileFilledIn(index)) {
					this.focusToInput(index - 1);
				}
			} else if (
				!this.isLetter(e.keyCode) &&
				!this.isTabOrShift(e.keyCode) &&
				!this.isEnter(e.keyCode) &&
				!this.isArrowKey(e.keyCode)
			) {
				e.preventDefault();
			}
		},
		focusToInput(index) {
			const input = this.$refs[`input-${index}`];
			if (input && input[0]) {
				input[0]?.focus();
			}
		},
		isTileFilledIn(index) {
			return this.correctTiles[index] !== "";
		},
		isFirstTile(index) {
			return index <= 0;
		},
		isLastTile(index) {
			return index >= this.correctTiles.length - 1;
		},
		isLetter(keyCode) {
			return (
				keyCode >= LETTER_RANGE_A_Z.START &&
				keyCode <= LETTER_RANGE_A_Z.END
			);
		},
		isSpacebar(keyCode) {
			return keyCode === KEY_CODE.SPACEBAR;
		},
		isTabOrShift(keyCode) {
			return keyCode === KEY_CODE.TAB || keyCode === KEY_CODE.SHIFT;
		},
		isEnter(keyCode) {
			return keyCode === KEY_CODE.ENTER;
		},
		isArrowKey(keyCode) {
			return keyCode === KEY_CODE.LEFT || keyCode === KEY_CODE.RIGHT;
		},
	},
};
