var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"asc relative",class:{
		'asc--has-focus': _vm.fieldHasFocus,
		'asc--results': _vm.isResultsPage,
	}},[_c('label',{staticClass:"asc__label bg-white absolute pointer-events-none leading-5",class:{
			'asc__label--results': _vm.isResultsPage,
			'asc__label--has-input': _vm.fieldHasInput,
			'asc__label--has-focus': _vm.fieldHasFocus,
		},attrs:{"for":_vm.fieldId}},[_vm._v("\n\t\t"+_vm._s(_vm.fieldLabel)+"\n\t")]),_vm._v(" "),_c('input',{staticClass:"asc__input outline-none h-10 md:h-11 uppercase bg-white text-black text-base font-medium w-full border-blueGray-100 border-2 border-solid rounded-[3px] m-0",class:{
			'md:h-13': !_vm.isResultsPage,
			'asc__input--results': _vm.isResultsPage,
			'asc__input--has-focus border-electric-blue': _vm.fieldHasFocus,
			'asc__input--has-input': _vm.fieldHasInput,
		},attrs:{"id":_vm.fieldId,"type":_vm.inputType,"title":_vm.fieldTitle,"autocomplete":"off","autocorrect":"false","spellcheck":"false","placeholder":_vm.placeholder,"aria-label":_vm.fieldAriaLabel,"maxlength":_vm.fieldMaxLength,"min":_vm.fieldMinValue,"max":_vm.fieldMaxValue},domProps:{"value":_vm.value},on:{"change":_vm.onFieldChange,"input":_vm.onFieldInput,"focus":_vm.onFieldFocus,"blur":_vm.onFieldBlur}}),_vm._v(" "),(_vm.fieldHasInput)?_c('button',{staticClass:"asc__button asc__button--clear text-black",class:{ 'asc__button--results': _vm.isResultsPage },attrs:{"type":"button"},on:{"click":_vm.onClearButtonClick}},[_c('span',{staticClass:"wficon-close-circle-i"})]):_c('button',{ref:"helpButton",staticClass:"asc__button asc__button--help text-blueGray-400",class:{ 'asc__button--results': _vm.isResultsPage },attrs:{"type":"button"},on:{"click":_vm.onHelpButtonClick}},[_c('span',{staticClass:"wficon-help-circle"})]),_vm._v(" "),(_vm.showHelpWindow)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onHelpWindowClickOutside),expression:"onHelpWindowClickOutside"}],staticClass:"help z-base-highest",class:{ 'help--contains': _vm.isContainsField },domProps:{"innerHTML":_vm._s(_vm.helpContent)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }